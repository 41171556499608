<template>
    <div class="front">
        <Slider />
    </div>
</template>
    
<script>
import Slider from './Slider.vue';

export default {

    data() {
        return {

        };
    },

    methods: {

    },

    components: { Slider },
};
</script>