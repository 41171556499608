<script>
    let currentSrc= 0;
    let currentImgIndex = 0;

    export default
    {
    currentSrc,
    currentImgIndex,
    }

</script>